import React from "react";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  facebook: {
    display: 'inline-block',
    verticalAlign: 'text-top',
    marginLeft: '10px',
    width: '170px',
  }
});

class FacebookLikeButton extends React.Component {

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.facebook}>
        <div className="fb-like" 
          data-href="https://www.facebook.com/lottointel" 
          data-layout="standard" 
          data-action="like" 
          data-size="small" 
          data-show-faces="false" 
          data-share="true">
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FacebookLikeButton);
