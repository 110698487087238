import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {viewTitleStyle} from './constants';
import Chart from 'react-google-charts';

const styles = theme => ({
  root: {
    margin: '10px auto 0 auto',
    padding: '10px',
    width: '1070px',
    boxSizing: 'border-box'
  },
  title: viewTitleStyle,
  flexBox: {
    minWidth: '380px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  pie: {
    margin: '0'
  }
});

class FilterStatisticsPieChartCollection extends Component {

  createPies() {
    let pies = [];
    const totalDraws = this.props.draws.length;
    this.props.results.forEach(result => {
      pies.push(<div key={result.rule}>
        <Chart width={'260px'} height={'200px'} chartType="PieChart" loader={<div> Loading Chart</div>} data={[
            [
              'Test Result', 'Violated Count'
            ],
            [
              `${ "\u2717"}:${result.draws.length}`, result.draws.length
            ],
            [
              `${ "\u2713"}:${ (totalDraws - result.draws.length)}`,
              (totalDraws - result.draws.length)
            ]
          ]} options={{
            title: result.rule,
            pieSliceTextStyle: {
              color: 'black',
            },
            chartArea: {
              width: '85%',
              height: '80%'
            },
            slices: {
              0: {
                color: '#F3D6E4'
              },
              1: {
                color: '#F1EA7F'
              }
            }
          }} rootProps={{
            'data-testid' : '1'
          }}/>
      </div>);
    });
    return pies;
  }

  render() {
    const {classes, draws} = this.props;
    return (<Paper className={classes.root}>
      <h1 className={classes.title}>
        Rule Statistics Pie Charts Over {draws.length} Historical Draws Since {draws[draws.length - 1]['drawDate']}
      </h1>
      <hr/>
      <div className={classes.flexBox}>{this.createPies()}</div>
    </Paper>);
  }
}

export default withStyles(styles)(FilterStatisticsPieChartCollection);