import _ from "underscore";
import React from 'react';

function getStatisticsTableResults(draws) {
  if (!draws) {
    return [];
  }

  var numberOccurrences = _.range(70).map(function() {
    return 0;
  });
  draws.forEach(draw => {
    draw["numbers"].forEach(number => {
      numberOccurrences[number] += 1;
    });
  });

  var result = [];
  var i;
  for (i = 1; i < numberOccurrences.length; i++) {
    result[i - 1] = {
      number: i,
      occurrenceCount: numberOccurrences[i],
      occurrenceRate: ((numberOccurrences[i] * 100) / draws.length).toFixed(3),
      evaluationDateRange: (`${draws[draws.length - 1]["drawDate"]} ~ ${draws[0]["drawDate"]}`).replace(/-/g, "/")
    };
  }

  return result;
}

function getPowerBallStatisticsTableResults(draws, powerBallRange) {
  if (!draws) {
    return [];
  }
  if (!powerBallRange) {
    powerBallRange = 45;
  }

  // upper bound is exclusive; thus + 1
  var numberOccurrences = _.range(powerBallRange + 1).map(function() {
    return 0;
  });
  draws.forEach(draw => {
    numberOccurrences[draw.powerball] += 1;
  });

  var result = [];
  var i;
  for (i = 1; i < numberOccurrences.length; i++) {
    result[i - 1] = {
      number: i,
      occurrenceCount: numberOccurrences[i],
      occurrenceRate: ((numberOccurrences[i] * 100) / draws.length).toFixed(3),
      evaluationDateRange: (`${draws[draws.length - 1]["drawDate"]} ~ ${draws[0]["drawDate"]}`).replace(/-/g, "/")
    };
  }

  return result;
}

function getPowerPlayStatisticsTableResults(draws, powerPlayRange) {
  if (!draws) {
    return [];
  }
  if (!powerPlayRange) {
    powerPlayRange = 10;
  }

  // upper bound is exclusive; thus + 1
  var numberOccurrences = _.range(powerPlayRange + 1).map(function() {
    return 0;
  });
  draws.forEach(draw => {
    numberOccurrences[draw.powerplay] += 1;
  });

  var result = [];
  var i;
  for (i = 1; i < numberOccurrences.length; i++) {
    result[i - 1] = {
      number: i,
      occurrenceCount: numberOccurrences[i],
      occurrenceRate: ((numberOccurrences[i] * 100) / draws.length).toFixed(3),
      evaluationDateRange: (`${draws[draws.length - 1]["drawDate"]} ~ ${draws[0]["drawDate"]}`).replace(/-/g, "/")
    };
  }

  return result;
}

function getStatisticsTableTitle(draws) {
  return `Non-Adjusted Number Statistics Over ${draws.length} Draws Since ${draws[draws.length - 1]['drawDate']}`;
}

function getPowerBallStatisticsTableTitle(draws) {
  return <span>Non-Adjusted PowerBall Statistics Since {draws[draws.length - 1]['drawDate']} ({draws.length} Draws)</span>;
}

function getPowerPlayStatisticsTableTitle(draws) {
  return `Non-Adjusted PowerPlay Statistics Over ${draws.length} Draws With PowerPlay`;
}

function getPowerBallStatisticsSince2015TableTitle(draws) {
  return <span>PowerBall Statistics<br/>Since The Last PowerBall Format Change On {draws[draws.length - 1]['drawDate']} ({draws.length} Draws)</span>;
}

export { getStatisticsTableResults, getPowerBallStatisticsTableResults, getStatisticsTableTitle, getPowerBallStatisticsTableTitle, getPowerBallStatisticsSince2015TableTitle, getPowerPlayStatisticsTableTitle, getPowerPlayStatisticsTableResults};
