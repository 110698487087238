import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import TicketGenerator from "./ticket_generator";
import GeneralNote from "./general_note";
import TicketChecker from "./ticket_checker";
import DrawHistory from "./draw_history_table";
import StatisticsChart from "./statistics_chart";
import PowerBallStatisticsChart from "./powerball_statistics_chart";
import PowerPlayStatisticsChart from "./powerplay_statistics_chart";
import StatisticsSince2015Chart from "./statistics_since_2015_chart";
import {getStatisticsTableTitle, getStatisticsTableResults, getPowerBallStatisticsTableTitle, getPowerBallStatisticsTableResults, getPowerBallStatisticsSince2015TableTitle, getPowerPlayStatisticsTableTitle, getPowerPlayStatisticsTableResults} from "./statistics_table_functions";
import PowerBallStatisticsSince2015Chart from "./powerball_statistics_since_2015_chart";
import {check_filters} from "./check_filters_functions";
import {allFilters} from "./filter_functions";
import {filterOldDraws, filterDrawsWithoutPowerPlay} from './utils/draw_utils';
import FilterStatisticsTable from "./filter_statistics_table";
import StatisticsTable from "./statistics_table";
import Grid from '@material-ui/core/Grid';
import FilterStatisticsPieChartCollection from './filter_statistics_pie_chart_collection';

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexFlow: "row wrap",
    boxSizing: 'border-box'
  }});

class LandingPage extends React.Component {
  componentDidMount() {
    fetch("https://s3-us-west-1.amazonaws.com/api.jethrolai.com/lottery/powerball/powerball-draws-latest.json").then(res => res.json()).then(result => {
      this.setState({
        isLoaded: true,
        draws: result
      }, () => {
        ReactDOM.render(<GeneralNote />, document.getElementById("generalNote"));
        ReactDOM.render(<TicketGenerator draws={this.state.draws}/>, document.getElementById("ticketGenerator"));
        ReactDOM.render(<TicketChecker draws={this.state.draws}/>, document.getElementById("ticketChecker"));
        ReactDOM.render(<FilterStatisticsPieChartCollection results={check_filters(this.state.draws, allFilters)} draws={this.state.draws}/>, document.getElementById("filterStatisticsPieChartCollection"));
        ReactDOM.render(<FilterStatisticsTable results={check_filters(this.state.draws, allFilters)} draws={this.state.draws}/>, document.getElementById("filterStatisticsTable"));
        ReactDOM.render(<StatisticsChart draws={this.state.draws}/>, document.getElementById("statistics"));
        ReactDOM.render(<DrawHistory draws={this.state.draws}/>, document.getElementById("drawHistory"));
        ReactDOM.render(<StatisticsSince2015Chart draws={this.state.draws}/>, document.getElementById("statisticsSince2015Chart"));
        ReactDOM.render(<StatisticsTable draws={filterOldDraws(this.state.draws, "2015-10-04")} powerBallRange={26} getTableTitle={getPowerBallStatisticsSince2015TableTitle} getTableResults={getPowerBallStatisticsTableResults} isLeft={false}/>, document.getElementById("powerBallStatisticsSince2015Table"));
        ReactDOM.render(<StatisticsTable draws={this.state.draws} getTableTitle={getStatisticsTableTitle} getTableResults={getStatisticsTableResults} isLeft={true}/>, document.getElementById("statisticsTable"));
        ReactDOM.render(<StatisticsTable draws={this.state.draws} getTableTitle={getPowerBallStatisticsTableTitle} getTableResults={getPowerBallStatisticsTableResults} isLeft={false}/>, document.getElementById("powerBallStatisticsTable"));
        ReactDOM.render(<StatisticsTable draws={filterDrawsWithoutPowerPlay(this.state.draws)} getTableTitle={getPowerPlayStatisticsTableTitle} getTableResults={getPowerPlayStatisticsTableResults} isLeft={true}/>, document.getElementById("powerPlayStatisticsTable"));
        ReactDOM.render(<PowerBallStatisticsChart draws={this.state.draws}/>, document.getElementById("powerBallStatisticsChart"));
        ReactDOM.render(<PowerBallStatisticsSince2015Chart draws={this.state.draws}/>, document.getElementById("powerBallStatisticsSince2015Chart"));
        ReactDOM.render(<PowerPlayStatisticsChart draws={this.state.draws}/>, document.getElementById("powerPlayStatisticsChart"));
      });
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    error => {
      this.setState({isLoaded: true, error});
    });
  }

  handleChange = (event, value) => {
    this.setState({value});
  };

  render() {
    const {classes} = this.props;

    return (<div className={classes.root}>
      <Grid container={true}>
        <Grid item={true} xs={true}>
          <div id="generalNote"/>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={true}>
          <div id="ticketGenerator"/>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={true}>
          <div id="ticketChecker"/>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={true}>
          <div id="filterStatisticsPieChartCollection"/>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={true}>
          <div id="statisticsSince2015Chart"/>
          <div id="powerBallStatisticsSince2015Chart"/>
          <div id="statisticsTable"/>
          <div id="powerBallStatisticsChart"/>
          <div id="powerPlayStatisticsTable"/>
        </Grid>
        <Grid item={true} xs={true}>
          <div id="filterStatisticsTable"/>
          <div id="drawHistory"/>
          <div id="powerBallStatisticsSince2015Table"/>
          <div id="statistics"/>
          <div id="powerBallStatisticsTable"/>
          <div id="powerPlayStatisticsChart"/>
        </Grid>
      </Grid>
    </div>);
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LandingPage);
