import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import './CheckFilterResultDetail.css';

class CheckFilterResultDetail extends Component {
  
  state = {
    'changeDialogOpen': false
  }
  
  handleDialogOpen = () => {
    this.setState({ 'changeDialogOpen': true });
  };

  handleDialogClose = () => {
    this.setState({ 'changeDialogOpen': false });
  };

  render() {    
    let detail;
    if (this.props.draws && this.props.draws.length !== 0) {
      detail = 
        <div >
          <span className='checkFilterResultDetail-title' onClick={this.handleDialogOpen}> See violated draws</span>
          <Dialog
            open={this.state.changeDialogOpen}
            onClose={this.handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Matched Draws of Rule - {this.props.rule}</DialogTitle>
            <DialogContent>
              <table className='checkFilterResultDetail-table'>
                <tbody>
                  <tr >
                    <th className='checkFilterResultDetail-th'>Draw Date</th>
                    <th className='checkFilterResultDetail-th'>Numbers</th>
                    <th className='checkFilterResultDetail-th'>PowerBall</th>
                  </tr>
                  {
                    this.props.draws.map( (draw) => (
                      <tr key={draw['drawDate']}>
                        <td className='checkFilterResultDetail-td'>{draw['drawDate']}</td>                      
                        <td className='checkFilterResultDetail-td'>{draw['numbers'].sort((a, b) => a - b).join(", ")}</td>
                        <td className='checkFilterResultDetail-td'>{draw['powerball']}</td>
                      </tr>
                      )
                    )
                  }
                </tbody>
              </table>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>;
    } else {
      detail = <span> No match detail.</span>;
    }
    
    return (
      <div>      
        {detail}
      </div>
    );
  }
}



export default CheckFilterResultDetail;