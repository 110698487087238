import React from "react";
import {withStyles} from "@material-ui/core/styles";
import { TwitterMentionButton } from 'react-twitter-embed';

const styles = theme => ({
  twitter: {
    display: 'inline-block',
    verticalAlign: 'text-top',
  }
});

class TwitterButton extends React.Component {

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.twitter}>
        <TwitterMentionButton screenName={'lottointel'}/>
      </div>
    );
  }
}

export default withStyles(styles)(TwitterButton);
