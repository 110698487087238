import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {isValidDrawDate} from './utils/draw_utils';
import TextField from '@material-ui/core/TextField';
import {TicketInputLabel, TicketInputSelect, TicketMenuItem} from './ticket_input_components';

const styles = theme => ({
  root: {
    // fontSize: "1em",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '15px'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 90
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 1
  },
  textField: {
    padding: "0 5px",
    margin: "7px 0 0 5px",
    fontWeight: 400,
    fontFamily: 'Thasadith, sans-serif'
  },
  input: {
    fontSize: "0.8em"
  },
  drawDateInput: {
    fontSize: "0.9em"
  },
  drawDateLabel: {
    fontSize: "0.9em",
    margin: "0 0 3px 3px",
    fontWeight: 400,
    fontFamily: 'Thasadith, sans-serif'
  }
});

function getSkipNumbers(ticket) {
  if (ticket && ticket.numbers && ticket.numbers.length !== 0) {
    let numbers = ticket.numbers.slice();
    return numbers.map(n => parseInt(n));
  }
  return [];
}

class TicketInput extends React.Component {

  constructor(props) {
    super(props)
    this.state.drawDates = this.props.draws.map(d => d.drawDate);
    this.state.numbers = this.props.ticket.numbers;
    this.state.powerball = this.props.ticket.powerball;
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handlePowerballChange = this.handlePowerballChange.bind(this)
    this.handleDrawDateChange = this.handleDrawDateChange.bind(this)
  }

  state = {
    numbers: null,
    powerball: null,
    drawDate: "",
    labelWidth: 0
  };

  handleNumberChange = event => {
    let numbers = this.state.numbers.slice();
    numbers[event.target.name] = event.target.value;
    this.setState({
      numbers: numbers
    }, () => {
      this.props.handleChange(this.state);
    });
  };
  handlePowerballChange = event => {
    this.setState({
      powerball: event.target.value
    }, () => {
      this.props.handleChange(this.state);
    });
  };

  handleDrawDateChange = event => {
    this.setState({
      drawDate: event.target.value
    }, () => {
      this.props.handleChange(this.state);
    });
  };

  createOptions(maxNumber, skipNumbers, optionOrder) {
    let options = [];
    var i, skipNumberIndex;

    for (i = 1; i <= maxNumber; i++) {
      var skip = false;
      for (skipNumberIndex = 0; skipNumberIndex < skipNumbers.length; skipNumberIndex++) {
        if (skipNumberIndex === optionOrder) 
          continue;
        if (parseInt(skipNumbers[skipNumberIndex]) === i) {
          skip = true;
          break;
        }
      }
      if (skip) 
        continue;
      options.push(<TicketMenuItem key={i} value={i}>{i}</TicketMenuItem>);
    }
    return options;
  }

  createSelect(classes, numberIndex, skipNumbers) {
    return (<FormControl className={classes.formControl} key={numberIndex}>
      <TicketInputLabel htmlFor="ticketInput">Number {numberIndex + 1}</TicketInputLabel>
      <TicketInputSelect value={this.state.numbers[numberIndex]} onChange={this.handleNumberChange} inputProps={{
          name: numberIndex.toString(),
          id: 'ticketInput',
          className: classes.input
        }}>
        {this.createOptions(69, skipNumbers, numberIndex)}
      </TicketInputSelect>
    </FormControl>);
  }

  showDrawDateError(drawDate, draws) {
    if (!drawDate) {
      return false;
    }
    return (isValidDrawDate(draws, drawDate) === false)
  }

  render() {
    const {classes, draws, ticket} = this.props;
    const {drawDate} = this.state;
    let numberSelects = [],
      i;
    const currentInputNumbers = getSkipNumbers(ticket);
    for (i = 0; i <= 4; i++) {
      numberSelects.push(this.createSelect(classes, i, currentInputNumbers));
    }

    return (<form className={classes.root} autoComplete="off">
      {numberSelects}
      <FormControl className={classes.formControl}>
        <TicketInputLabel htmlFor="ticketInput">PowerBall</TicketInputLabel>
        <TicketInputSelect value={this.state.powerball} onChange={this.handlePowerballChange} inputProps={{
            id: 'ticketInput',
            className: classes.input
          }}>
          {
            this.createOptions(
              26, ticket.powerball
              ? [parseInt(ticket.powerball)]
              : [],
            0)
          }
        </TicketInputSelect>
      </FormControl>
      <TextField error={this.showDrawDateError(drawDate, draws)} id="drawDate" label="Date (Optional)" className={classes.textField} value={drawDate} helperText="YYYY-MM-DD" margin="none" onChange={this.handleDrawDateChange} InputProps={{
          className: classes.drawDateInput
        }} InputLabelProps={{
          FormLabelClasses: {
            root: classes.drawDateLabel
          }
        }}/>
    </form>);
  }
}

TicketInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TicketInput);
