import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const NavButton = withStyles({
  root: {
    height: 24,
    fontWeight: '400',
    fontFamily: 'Thasadith, sans-serif',
    color: 'DimGray'
  },
  label: {
    textTransform: 'capitalize'
  }
})(Button);

export default NavButton;
