import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './header';
import Footer from './footer';
import LandingPage from './landing_page';
import packageJson from '../package.json';

ReactDOM.render(<Header />, document.getElementById('header'));
ReactDOM.render(<LandingPage />, document.getElementById('root'));
ReactDOM.render(<Footer />, document.getElementById('footer'));
document.getElementById('appVersion').innerHTML = packageJson.version;
