import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    background: 'AliceBlue',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const {children, classes, onClose} = props;
  return (<MuiDialogTitle disableTypography={true} className={classes.root}>
    <Typography variant="h6">{children}</Typography>
    {
      onClose
        ? (<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>)
        : null
    }
  </MuiDialogTitle>);
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
    fontSize: '1.1em',
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
    background: 'AliceBlue',
  }
}))(MuiDialogActions);

const styles = theme => ({
  lottoIntelligence: {
    color: 'SteelBlue',
    textShadow: '2px 2px 2px #b7d9ff'
  }
});

class About extends React.Component {
  
  
  
  render() {
    const {classes} = this.props;
    const lottoIntelligenceName = 
      <span className={classes.lottoIntelligence}>
        &nbsp;Lotto Intelligence
      </span>;
    return (<span>
      <Dialog onClose={this.props.handleClose} aria-labelledby="about-dialog-title" open={this.props.open} fullWidth={true} maxWidth={'md'}>
        <DialogTitle id="about-dialog-title" onClose={this.props.handleClose}>
          About{lottoIntelligenceName}
        </DialogTitle>
        <DialogContent>
            Playing lottery game is an awesome entertainment and it’s even more fun when you play it smart.
            <br/>
            <br/>
            Most of lottery sites provide things like next draw date, winning numbers, current jackpot prize, probabilities, rules and nothing more. There are so many numbers, metrics, stories and inspirations behind each lottery game. Also those sites don't really offer any way to help you pick a number that works best for you. This is why we created {lottoIntelligenceName}.
            <br/>
            <br/>
            On{lottoIntelligenceName}, not only do you get to see lottery data in a brand new way but also play the game in a way that makes more sense. We provide
            <em>
              <ul>
                <li>various analyses and statistics against entire PowerBall history</li>
                <li>data visualization</li>
                <li>number generation with preferred rules and filters.</li>
                <li>rules and filters statistics</li>
                <li>number checking with rules</li>
              </ul>
            </em>
            We also plan on implementing features like automatic winning notification so that you can log your ticket with us and never have to worry about checking it. We will check the ticket for you and notify you if your ticket is a winner. Additionally, ticket management is also on the roadmap. Currently, we are supporting PowerBall only but soon other major lottery games such as Mega Millions, SuperLotto Plus, Lotto America and so on will be supported in the future.
            <br/>
            <br/>
            Another important thing is it's absolutely <b>FREE</b> for everyone.
            <br/>
            <br/>
            Please feel free to give us feedback and suggestions. We are eager to hear from you and improve what we do. We hope you enjoy our site and wish the best of luck to you!
            <br/>
            <br/>
            <i>–{lottoIntelligenceName}
            <br/>
              <a className={classes.lottoIntelligence} href="mailto:lottointelligence@gmail.com">lottointelligence@gmail.com</a>
            </i>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </span>);
  }
}

export default withStyles(styles)(About);
