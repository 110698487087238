import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';
import {
  stableSort,
  getSorting
} from './utils/sort';
import { viewTitleStyle }from './constants';

function createData(draws) {
  return draws.map((r) => {
    return { number: r.number, occurrenceCount: r.occurrenceCount, occurrenceRate: r.occurrenceRate, evaluationDateRange: r.evaluationDateRange }
  });
}

const rows = [
  {
    id: 'number',
    label: 'Number'
  }, {
    id: 'occurrenceRate',
    label: 'Rate (%)'
  }, {
    id: 'occurrenceCount',
    label: 'Count'
  }, {
    id: 'evaluationDateRange',
    label: 'Evaluation Date Range'
  }
];

const tableHeadStyles = theme => ({
  headCell: {
    textAlign: 'left',
    fontWeight: '400',
    padding: '0 0 0 20px',
  }
});

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {classes, order, orderBy} = this.props;
    return (<TableHead>
      <TableRow>
        {
          rows.map(row => {
            return (<TableCell className={classes.headCell} key={row.id} sortDirection={orderBy === row.id
                ? order
                : false}>
              <Tooltip title="Sort" placement={'bottom-end'} enterDelay={300}>
                <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>);
          }, this)
        }
      </TableRow>
    </TableHead>);
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

EnhancedTableHead = withStyles(tableHeadStyles)(EnhancedTableHead);

const toolbarStyles = theme => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: viewTitleStyle,
});

let EnhancedTableToolbar = props => {
  const {classes, draws, getTableTitle} = props;

  return (<div>
    <h1 className={classes.title}>
      {getTableTitle(draws)}
    </h1>
    <div className={classes.spacer}/>
  </div>);
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  rootForLeft: {
    margin: '10px auto 0 15px',
    padding: '10px',
    width: '530px',
    boxSizing: 'border-box',
  },
  rootForRight: {
    margin: '10px 15px 0 auto',
    padding: '10px',
    width: '530px',
    boxSizing: 'border-box',
  },
  table: {
    minWidth: '380px',
    maxWidth: '480px'
  },
  tableWrapper: {
    overflowX: 'auto',
    maxWidth: '480px'
  }, 
  dataCell: {
    textAlign: 'left',
    padding: '0 0 0 20px'
  }, 
  evaluationDateRangeCell: {
    textAlign: 'left',
    padding: '0'
  }
});

class EnhancedTable extends React.Component {

  state = {
    order: 'desc',
    orderBy: 'occurrenceRate',
    data: createData(this.props.getTableResults(this.props.draws, this.props.powerBallRange)),
    page: 0,
    rowsPerPage: 5,
    draws: this.props.draws
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage: event.target.value});
  };

  render() {
    const {classes, getTableTitle, isLeft} = this.props;
    const {data, order, orderBy, rowsPerPage, page} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    let style = isLeft ? classes.rootForLeft: classes.rootForRight;
    return (
      <Grow in={true}>
        <Paper className={style}>
          <EnhancedTableToolbar draws={this.state.draws} getTableTitle={getTableTitle}/>
          <hr/>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} rowCount={data.length} draws={this.state.draws}/>
              <TableBody>
                {
                  stableSort(data, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                    return (                  
                      <TableRow hover={true} tabIndex={-1} key={n.number}>
                        <TableCell className={classes.dataCell} component="th" scope="row">
                          {n.number}
                        </TableCell>                        
                        <TableCell className={classes.dataCell} >{n.occurrenceRate}</TableCell>
                        <TableCell className={classes.dataCell} >{n.occurrenceCount}</TableCell>
                        <TableCell className={classes.evaluationDateRangeCell} >{n.evaluationDateRange}</TableCell>
                      </TableRow>);
                  })
                }
                {
                  emptyRows > 0 && (<TableRow style={{
                      height: 49 * emptyRows
                    }}>
                    <TableCell colSpan={3}/>
                  </TableRow>)
                }
              </TableBody>
            </Table>
          </div>
          <TablePagination rowsPerPageOptions={[5, 20, data.length]} component="div" count={data.length} rowsPerPage={rowsPerPage} page={page} backIconButtonProps={{
              'aria-label' : 'Previous Page'
            }} nextIconButtonProps={{
              'aria-label' : 'Next Page'
            }} onChangePage={this.handleChangePage} onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
        </Paper>
      </Grow>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnhancedTable);
