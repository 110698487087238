import React from 'react';
import FilterSwitch from './filter_switch';
import {
  checkHasAppeared,
  checkThreeConsecutive,
  checkTwoConsecutive,
  checkAllOddNumbersWithPowerball,
  checkAllOddNumbers,
  checkAllEvenNumbersWithPowerball,
  checkAllEvenNumbers,
  checkPowerBallAndNumberOverlap,
  filterLabelMap,
} from './filter_functions';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  flexBox: {
    minWidth: '380px',
    display: 'flex',
    flexWrap: 'wrap',
  }, 
  option: {
    width: '350px',
  }
});

class FilterOptions extends React.Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
  }

  state = {
    checkHasAppeared: false,
    checkThreeConsecutive: false,
    checkTwoConsecutive: false,
    checkAllOddNumbersWithPowerball: false,
    checkAllOddNumbers: false,
    checkAllEvenNumbersWithPowerball: false,
    checkAllEvenNumbers: false,
    checkPowerBallAndNumberOverlap: false,
    selectedAll: false
  };

  handleSelectAll() {
      this.setState({selectedAll: !this.state.selectedAll}, () => {
        this.props.handleSelectAll(this.collectFilters());
      });
  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.checked
    }, () => { //TODO Not sure why but if this is not wrapped up in an anonymous function, this does not work properly.
      this.props.handleChange(this.collectFilters());
    });
  };

  collectFilters() {
    var filters = [];
    if (this.state.checkHasAppeared || this.state.selectedAll) {
      filters.push(checkHasAppeared);
    }
    if (this.state.checkThreeConsecutive || this.state.selectedAll) {
      filters.push(checkThreeConsecutive);
    }
    if (this.state.checkTwoConsecutive || this.state.selectedAll) {
      filters.push(checkTwoConsecutive);
    }
    if (this.state.checkAllOddNumbersWithPowerball || this.state.selectedAll) {
      filters.push(checkAllOddNumbersWithPowerball);
    }
    if (this.state.checkAllOddNumbers || this.state.selectedAll) {
      filters.push(checkAllOddNumbers);
    }
    if (this.state.checkAllEvenNumbersWithPowerball || this.state.selectedAll) {
      filters.push(checkAllEvenNumbersWithPowerball);
    }
    if (this.state.checkAllEvenNumbers || this.state.selectedAll) {
      filters.push(checkAllEvenNumbers);
    }
    if (this.state.checkPowerBallAndNumberOverlap || this.state.selectedAll) {
      filters.push(checkPowerBallAndNumberOverlap);
    }
    return filters;
  }


  render() {
    const {classes} = this.props;
    return (
      <div className={classes.flexBox}>
            <Tooltip title="Select all filters">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.selectedAll} onChange={this.handleSelectAll}/>Select All
              </div>
            </Tooltip>
            <Tooltip title="Avoid generating the combination which has ever appeared in history.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkHasAppeared || this.state.selectedAll} onChange={this.handleChange('checkHasAppeared')} value='checkHasAppeared' disabled={this.state.selectedAll}/>{filterLabelMap['checkHasAppeared']}
              </div>
            </Tooltip>
            <Tooltip title="Advoid generating the combination which has 3 consecutive numbers.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkThreeConsecutive || this.state.selectedAll} onChange={this.handleChange('checkThreeConsecutive')} value='checkThreeConsecutive' disabled={this.state.selectedAll}/>{filterLabelMap['checkThreeConsecutive']}
              </div>
            </Tooltip>
            <Tooltip placement={"right-start"} title="Advoid generating the combination which has 2 consecutive numbers.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkTwoConsecutive || this.state.selectedAll} onChange={this.handleChange('checkTwoConsecutive')} value='checkTwoConsecutive' disabled={this.state.selectedAll}/>{filterLabelMap['checkTwoConsecutive']}
              </div>
            </Tooltip>
            <Tooltip title="Advoid generating the ticket numbers which contain only odd numbers including Powerball number.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkAllOddNumbersWithPowerball || this.state.selectedAll} onChange={this.handleChange('checkAllOddNumbersWithPowerball')} value='checkAllOddNumbersWithPowerball' disabled={this.state.selectedAll}/>{filterLabelMap['checkAllOddNumbersWithPowerball']}
              </div>
            </Tooltip>
            <Tooltip title="Advoid generating the ticket numbers which contain only odd numbers excluding Powerball number.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkAllOddNumbers || this.state.selectedAll} onChange={this.handleChange('checkAllOddNumbers')} value='checkAllOddNumbers' disabled={this.state.selectedAll}/>{filterLabelMap['checkAllOddNumbers']}
              </div>
            </Tooltip>
            <Tooltip title="Advoid generating the combination which has only even numbers including Powerball number.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkAllEvenNumbersWithPowerball || this.state.selectedAll  } onChange={this.handleChange('checkAllEvenNumbersWithPowerball')} value='checkAllEvenNumbersWithPowerball' disabled={this.state.selectedAll}/>{filterLabelMap['checkAllEvenNumbersWithPowerball']}
              </div>
            </Tooltip>
            <Tooltip title="Advoid generating the combination which has only even numbers excluding Powerball number.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkAllEvenNumbers || this.state.selectedAll  } onChange={this.handleChange('checkAllEvenNumbers')} value='checkAllEvenNumbers' disabled={this.state.selectedAll}/>{filterLabelMap['checkAllEvenNumbers']}
              </div>
            </Tooltip>
            <Tooltip title="Advoid generating a ticket which powerball equals to any of numbers.">
              <div className={classes.option}>
                <FilterSwitch checked={this.state.checkPowerBallAndNumberOverlap || this.state.selectedAll} onChange={this.handleChange('checkPowerBallAndNumberOverlap')} value='checkPowerBallAndNumberOverlap' disabled={this.state.selectedAll}/>{filterLabelMap['checkPowerBallAndNumberOverlap']}
              </div>
            </Tooltip>
      </div>
    );
  }
}
export default withStyles(styles)(FilterOptions);