import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import TicketInput from './ticket_input';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {checkTicketWithFilters, isValidTicket, isWinner} from './ticket_functions';
import {allFilters} from './filter_functions';
import CheckTicketResult from './check_ticket_result';
import { viewTitleStyle }from './constants';

const styles = theme => ({
  root: {
    margin: '10px auto 0 auto',
    padding: '10px',
    width: '1070px',
    boxSizing: 'border-box',
  },
  title: viewTitleStyle,
  button: {
    backgroundColor: 'black',
    margin: '10px 0 10px 20px'
  },
  errorMessage: {
    color: 'Crimson',
    fontSize: '1em',
    marginLeft: '25px',
  }  
});

class TicketChecker extends Component {

  constructor(props) {
    super(props)
    this.state = {
      "resultDisplayed": false,
      "inputTicket": {
        numbers: ["","","","",""],
        powerball:"",
      }
    };
    this.checkTicket = this.checkTicket.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  handleInputChange = inputTicket => {
    this.setState({"inputTicket": inputTicket}, this.checkTicket);
  }

  checkTicket() {
    this.clearResult();
    let validityCheckResult = isValidTicket(this.state.inputTicket, this.props.draws);
    let filterCheckResult = checkTicketWithFilters(this.state.inputTicket, allFilters, this.props.draws);
    let winnerCheckResult = isWinner(this.state.inputTicket, this.props.draws);
    
    ReactDOM.render(
      <CheckTicketResult 
        ticket={this.state.inputTicket} 
        filterCheckResult={filterCheckResult} 
        winnerCheckResult={winnerCheckResult} 
        validityCheckResult={validityCheckResult} 
        animated={this.state.resultDisplayed===false}
      />, 
      document.getElementById('check-ticket-result'));
    this.setState({
      "resultDisplayed": true
    });
  }

  clearResult() {
    ReactDOM.unmountComponentAtNode(document.getElementById('result'));
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <h1 className={classes.title}>
          Ultimate PowerBall Ticket Checker
        </h1>
        <hr/>
        <ol>
          <li>Check if the input ticket is a winner. The draw date is optional. If the draw date is not provided, it will check this ticket with all historical draws.</li>
          <li>Check if the input ticket passes any rules.</li>
        </ol>
        <Grid container>
          <Grid item>
            <TicketInput handleChange={this.handleInputChange} ticket={this.state.inputTicket} draws={this.props.draws}/>
          </Grid>
        </Grid>
        <br/>
        <div id="check-ticket-result"/>
      </Paper>
    );
  }
}



export default withStyles(styles)(TicketChecker);