import {
  lookUpLabel
} from './filter_functions';
import {
  isValidDate,
  isValidDrawDate
} from './utils/draw_utils';

//This function checks the input ticket against CURRENT PowerBall rules
function isValidTicket(ticket, draws) {  
  let result;
  if (!ticket) {
    return {
      status: false,
      reason: "The input ticket is null or undefined",
      displayReason: "There is no input ticket",
      drawDateStatus: false,
      drawDateReason: "The input ticket is null or undefined",
      drawDateDisplayReason: "There is no input ticket"
    };
  }
  if (!result && (typeof ticket === "object") === false) {
    result = {
      status: false,
      reason: "The input ticket is not an object",
      displayReason: "Internal error. Please contact the technical support",
    };
  }
  if (!result && ticket.hasOwnProperty('numbers') === false) {
    result = {
      status: false,
      reason: "The input ticket does not have \"numbers\"",
      displayReason: "Internal error. Please contact the technical support",
    };
  }
  if (!result && !ticket.numbers) {
    result = {
      status: false,
      reason: "The numbers of input ticket is null, undefined or does not exist",
      displayReason: "Internal error. Please contact the technical support",
    };
  }
  if (!result && Array.isArray(ticket.numbers) === false) {
    result = {
      status: false,
      reason: "The numbers of input ticket is not an array",
      displayReason: "Internal error. Please contact the technical support",
    };
  }
  if (!result && ticket.numbers.length !== 5) {
    result = {
      status: false,
      reason: "The numbers of input ticket does not have 5 elements",
      displayReason: "Missing one or multiple main number(s). Any valid ticket must have exact 5 main numbers",
    };
  }
  if (!result && ticket.numbers.find(n => isNaN(n))) {
    result = {
      status: false,
      reason: "The numbers of input ticket contains non-number",
      displayReason: "Internal error. Please contact the technical support",
    };
  }
  if (!result && ticket.numbers.filter(n => !n).length !== 0) {
    result = {
      status: false,
      reason: "The numbers of input ticket contains undefined, null or empty string",
      displayReason: "Missing one or multiple main number(s). Any valid ticket must have exact 5 main numbers",
    };
  }
  if (!result && ticket.numbers.find(n => n < 1 || n > 69) !== undefined) {
    result = {
      status: false,
      reason: "The numbers of input ticket contains out of bound number (< 1 or > 69)",
      displayReason: "Internal error. Please contact the technical support",
    };
  }

  //TODO: AdphIhYE we can handle this
  if (!result && new Set(ticket.numbers.map(n=> parseInt(n))).size !== ticket.numbers.length) {
    result = {
      status: false,
      reason: "The numbers of input ticket contains duplicates",
      displayReason: "The main numbers contains duplicates",
    };
  }
  if (!result && !ticket.powerball) {
    result = {
      status: false,
      reason: "The input ticket does not have powerball or powerball field is null, undefined, or empty string",
      displayReason: "Missing PowerBall number. Any valid ticket must have one PowerBall number",
    };
  }
  if (!result && isNaN(ticket.powerball)) {
    result = {
      status: false,
      reason: "The powerball of input ticket is not a number",
      displayReason: "Internal error. Please contact the technical support",
    };
  }

  if (!result && (ticket.powerball > 26 || ticket.powerball < 1)) {
    result = {
      status: false,
      reason: "The powerball of input ticket is out of bound ( < 1 or > 26)",
      displayReason: "Internal error. Please contact the technical support",
    };
  }
  result = result ? result : {
    status: true
  };
  
  if ( result.status ===false ) {
    result.drawDateStatus = false;
    result.drawDateReason = "Skipping draw date validation because the input ticket is invalid";
    result.drawDateDisplayReason = "Skipping draw date validation because the input ticket is invalid";
  }

  if (!ticket.drawDate || ticket.hasOwnProperty('drawDate') === false) {
    result.drawDateStatus = false;
    result.drawDateReason = "The drawDate of the ticket is missing, null, undefined or empty";
    result.drawDateDisplayReason = "The drawDate is not provided";
  }

  if (isValidDrawDate(draws, ticket.drawDate)) {
    result.drawDateStatus = true;
  } else if (isValidDate(ticket.drawDate)) {
    result.drawDateStatus = false;
    result.drawDateReason = "The drawDate of the ticket is in valid format but the date is not eligible";
    result.drawDateDisplayReason = "The draw date of the ticket is in valid format but the date is not eligible; There is no drawing on " + ticket.drawDate;
  } else {
    result.drawDateStatus = false;
    result.drawDateReason = "The drawDate of the ticket is malformatted";
    result.drawDateDisplayReason = "The draw date of the ticket is invalid";
  }

  return result;
}

function isWinner(ticket, draws) {
  let startTime = new Date();
  var result = {
    winners: []
  };
  //TODO this should check with legacy PowerBall rules as well
  if (isValidTicket(ticket, draws).status === false) {
    result.time = startTime.toLocaleTimeString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "numeric"
    });
    result.elasedMillisecond = new Date() - startTime;
    return result;
  }

  draws.forEach(d => {
    // let numberHits = _.intersection(ticket.numbers, d);
    let numbers = ticket.numbers.slice();

    let numberHits = numbers.filter(number => d.numbers.includes(number.toString()));

    numberHits = numberHits.filter(number => -1 !== numberHits.indexOf(number));
    let powerballHit = ticket.powerball.toString() === d.powerball;
    if (numberHits.length >= 3 || powerballHit === true) {
      result.winners.push({
        numberHits: numberHits.length,
        powerballHit: powerballHit,
        draw: d
      });
    }
  });

  result.time = startTime.toLocaleTimeString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric"
  });
  result.elasedMillisecond = new Date() - startTime;
  return result;
}

function checkTicketWithFilters(ticket, filters, draws) {
  let result = {};
  let startTime = new Date();
  if (filters === undefined || Array.isArray(filters) === false) {
    return ticket;
  }

  let failedFilters = filters.filter(f => f(ticket, draws));
  let passedFilters = filters.filter(f => f(ticket, draws) === false);
  result.filters = filters.map(f => lookUpLabel(f));
  result.failedFilters = failedFilters.map(f => lookUpLabel(f));
  result.passedFilters = passedFilters.map(f => lookUpLabel(f));
  result.time = startTime.toLocaleTimeString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric"
  });

  result.elasedMillisecond = new Date() - startTime;
  return result;
}

function generate_ticket(filters, draws) {
  let startTime = new Date();
  var generated = generate_candidate();
  if (filters === undefined || Array.isArray(filters) === false) {
    return generated;
  }

  // while (filters.find(f => f(generated, draws))) {
  while (findAnyFilter(filters, draws, generated)) {
    generated = generate_candidate();
  }
  generated.filters = filters.map(f => lookUpLabel(f));
  generated.time = new Date().toLocaleTimeString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric"
  });

  generated.elasedTime = new Date() - startTime;
  return generated;
}

function findAnyFilter(filters, draws, generated) {
  return filters.find(f => f(generated, draws));
}

function generate_candidate() {
  var generated = {
    'numbers': [],
    'powerball': undefined
  };
  var taken = [];
  while (generated['numbers'].length < 5) {
    var number = Math.floor(Math.random() * 69) + 1;
    if (taken[number] === undefined) {
      generated['numbers'].push(number);
      taken[number] = true;
    }
  }

  //sort() is sorting by alphanumeric order instead of numeric, so need the arrow function
  generated['numbers'].sort((a, b) => a - b);
  generated.powerball = Math.floor(Math.random() * 26) + 1;
  return generated;
}

export {
  generate_ticket,
  generate_candidate,
  checkTicketWithFilters,
  isValidTicket,
  isWinner
}