import React, {Component} from 'react';
import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: '10px',
  },
  title: {
    fontSize: '1.2em',
    margin: '0 0 0 10px',
    fontWeight: 'bold',
  },
  resultSummaryGreen: {
    color: 'MediumSeaGreen',
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '10px 0 0 10px',
  },
  resultSummaryRed: {
    color: 'Crimson',
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '10px 0 0 10px',
  },
  invalidNumbers: {
    color: 'Crimson',
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '10px 0 0 10px',
  },
  invalidReason: {
    color: 'Crimson',
    margin: '10px 0 0 10px',
  },
  metadataTitle: {
    fontSize: '1em',
    padding: '10px 0 0 10px',
    margin: '3px'
  },
  metadataContent: {
    color: 'DarkSlateBlue',
    fontSize: '1em',
    margin: '5px 0 0 10px'
  },
  passedText: {
    color: 'MediumSeaGreen',
    fontSize: '1em',
  },
  errorText: {
    color: 'Crimson',
    fontSize: '1em',
  },
  warningText: {
    color: 'DarkOrange',
    fontSize: '1em',
  },
  notWinner: {
    color: 'Crimson',
    fontSize: '1em',
    marginLeft: '10px',
  }
});

function decorateWithGrow(element, timeout) {
  return <Grow 
    in={true}
    style={{ transformOrigin: '0 0 0' }}
    timeout={timeout}
    >
    {element}
  </Grow>;
}

class CheckTicketResult extends Component {
  
  render() {    
    const { classes } = this.props;
    let validityCheckResult;
    let drawDateValidityCheckResult = "";
    if (this.props.validityCheckResult.drawDateStatus === false && this.props.ticket.drawDate) {
      if (this.props.validityCheckResult.status === false) {
        drawDateValidityCheckResult = <li key={this.props.validityCheckResult.drawDateDisplayReason} className={classes.errorText}>&#10007; {this.props.validityCheckResult.drawDateDisplayReason}</li>;
      } else {
        drawDateValidityCheckResult = 
          <ul style={{margin:"5px"}}>
          <li key={this.props.validityCheckResult.drawDateDisplayReason} className={classes.warningText}>&#10007; {this.props.validityCheckResult.drawDateDisplayReason}</li>
        </ul>
      }
    }
    
    if (this.props.validityCheckResult.status === false) {
      validityCheckResult = 
      <div className={classes.root}>
        <div className={classes.title}>Validity Check Result: </div>
        <div className={classes.invalidNumbers}>{this.props['ticket']['numbers'].join(", ")} ({this.props['ticket']['powerball']}) is not a valid ticket according to current PowerBall rules</div>
        <ul style={{margin:"5px"}}>
          <li key={this.props.validityCheckResult.displayReason} className={classes.errorText}>&#10007; {this.props.validityCheckResult.displayReason}</li>
          {drawDateValidityCheckResult}
        </ul>
      </div>;
    } else {
      validityCheckResult = 
       <div className={classes.root}>
         <div className={classes.title}>Validity Check Result: </div>
         <div className={classes.resultSummaryGreen}>{this.props['ticket']['numbers'].join(", ")} ({this.props['ticket']['powerball']}) is a valid ticket</div>
         {drawDateValidityCheckResult}
       </div>;
    }
        
    let filterCheckResult;
    // for robustness. This conditin is unlikely unless we remove all filters/rules
    if (this.props.validityCheckResult.status === false) {
      filterCheckResult = <div/>;
    } else if(!this.props.filterCheckResult.filters || this.props.filterCheckResult.filters.length === 0) {
      filterCheckResult = 
      <div className={classes.root}>
        <div className={classes.title}>Filter Check Result: </div>
        <div className={classes.errorText}> No filters applied! </div>
      </div>;
    } else {
      filterCheckResult = 
      <div className={classes.root}>
        <div className={classes.title}>Filter Check Result:</div>
        <ul style={{margin:"5px"}}>
          {this.props.filterCheckResult.passedFilters.map((filter) => <li key={filter} className={classes.passedText}>&#10003; {filter}</li>)}
          {this.props.filterCheckResult.failedFilters.map((filter) => <li key={filter} className={classes.errorText}>&#10007; {filter}</li>)}
        </ul>
        <div className={classes.metadataTitle}> checked at <b>{this.props.filterCheckResult.time}</b> and took <b>{this.props.filterCheckResult.elasedMillisecond} milliseconds</b></div>
      </div>;
    }
    
    let winnerCheckResult;    
    if (this.props.validityCheckResult.status === false) {
      winnerCheckResult = <div/>;
    } else if(!this.props.winnerCheckResult.winners || this.props.winnerCheckResult.winners.length === 0) {
      winnerCheckResult = 
      <div className={classes.root}>
        <div className={classes.title}>Winner Check Result: </div>
        <div className={classes.notWinner}>Not a winner! </div>
      </div>;
    } else {
      let targetDateWinnerCheckResult = <span/>;
      if (this.props.validityCheckResult.drawDateStatus){
        if(this.props.winnerCheckResult.winners.find(winner=>winner.draw.drawDate===this.props.ticket.drawDate)) {
          targetDateWinnerCheckResult= <div className={classes.resultSummaryGreen}>{this.props.ticket.numbers.join(", ")} ({this.props.ticket.powerball}) is a winner for {this.props.ticket.drawDate}!</div>
        } else {
          targetDateWinnerCheckResult= <div className={classes.resultSummaryRed}>{this.props.ticket.numbers.join(", ")} ({this.props.ticket.powerball}) is not a winner for {this.props.ticket.drawDate}!</div>
        }
      }
            
      winnerCheckResult = 
      <div className={classes.root}>
        <div className={classes.title}>Winner Check Result:</div>
        {targetDateWinnerCheckResult}
        <div className={classes.resultSummaryGreen}>{this.props['ticket']['numbers'].join(", ")} ({this.props['ticket']['powerball']}) has won {this.props.winnerCheckResult.winners.length} {this.props.winnerCheckResult.winners.length===0?"time":"times"} in the history!</div>
        <br/>
        <ul style={{margin:"5px"}}>
          {this.props.winnerCheckResult.winners.map((winner) => <li key={winner.draw.drawDate} >winner of <b className={classes.passedText}>{winner.draw.drawDate}</b> ! <b className={classes.passedText}>{winner.numberHits}</b> matched and PowerBall is <b className={classes.passedText}>{winner.powerballHit===true?"":"not"} matched</b> </li>)}
        </ul>
        <div className={classes.metadataTitle}> checked at <b>{this.props.winnerCheckResult.time}</b> and took <b>{this.props.winnerCheckResult.elasedMillisecond} milliseconds</b></div>
      </div>;
    }
          
    if(this.props.animated) {
      validityCheckResult = decorateWithGrow(validityCheckResult, 700);
      filterCheckResult = decorateWithGrow(filterCheckResult, 1500);
      winnerCheckResult = decorateWithGrow(winnerCheckResult, 2300);
    }
    
    return (
      <div>
        <hr/>
        {validityCheckResult}
        {filterCheckResult}
        {winnerCheckResult}
      </div>
    );
  }
}

export default withStyles(styles)(CheckTicketResult);

