import {withStyles} from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const TicketInputLabel = withStyles({
  root: {
    fontSize: "0.9em",
    fontWeight: '400',
    fontFamily: 'Thasadith, sans-serif',
    color: 'DimGray',
  }
})(InputLabel);

const TicketInputSelect = withStyles({
  select: {
    padding: "3px",
  }
})(Select);

const TicketMenuItem = withStyles({
  gutters: {
    fontSize: "1em",
    padding: "0 5px",
    margin: "0",
    fontWeight: '400',
    fontFamily: 'Thasadith, sans-serif',
    color: 'DimGray',
  }
})(MenuItem);

export {
  TicketInputLabel, TicketInputSelect, TicketMenuItem
};
