import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import GenerateButton from './generate_button';
import { generate_ticket } from './ticket_functions';
import GeneratedTicket from './GeneratedTicket';
import FilterOptions from './filter_options';
import { viewTitleStyle }from './constants';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    margin: '0 auto',
    padding: '10px',
    width: '1070px',
    boxSizing: 'border-box',
  },
  title: viewTitleStyle,
  button: {
    margin: '20px 0 10px 10px',
  },
  divider: {
    marginTop: '33px',
  },
  content: {
    opacity: 1
  }
});

class TicketGenerator extends Component {

  constructor(props) {
    super(props)
    this.state = {
      "filters": [], 
      "resultDisplayed": false
    }
  }

  generateRandomTicket() {
    this.clearResult();
    this.setState({
      "generatedTicket": generate_ticket(this.state['filters'], this.props.draws),
    }, () => {
      ReactDOM.render(<GeneratedTicket ticket={this.state['generatedTicket']} animated={this.state.resultDisplayed===false}/>, document.getElementById('result'));
      this.setState({
        "resultDisplayed": true
      });
    });
  }

  clearResult() {
    ReactDOM.unmountComponentAtNode(document.getElementById('result'));
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <div className={classes.content}>
        <h1 className={classes.title}>
          PowerBall Ticket Generator
        </h1>
        <hr/>
        <FilterOptions handleChange={(filters) => this.setState({"filters": filters})} handleSelectAll={(filters) => this.setState({"filters": filters})}/>
        <Grid container={true}>
          <Grid item={true} xs={1}>
            <GenerateButton className={classes.button} size="small" color="primary" variant="contained" onClick={this.generateRandomTicket.bind(this)} >
              Generate
            </GenerateButton>
          </Grid>
          <Grid item={true} xs={11}>
            <hr className={classes.divider} />
          </Grid>
        </Grid>
        
        <div id="result"/>
        </div>
      </Paper>
    );
  }
}



export default withStyles(styles)(TicketGenerator);