import React from "react";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  background: {
    backgroundColor: 'LightSlateGray',
    color: 'white',
    position: 'fixed',
    left: '0',
    bottom: '0',
    opacity: '0.9',
    width: '100%',
    textAlign: 'center',
    fontSize: '1.1em',
  },
  root: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    minWidth: '740px',
    maxWidth: '1100px',
    height: '45px',
    display: 'inline-block',
    textAlign: 'left',
  },
  note: {
    width: '600px',
    padding: '5px 0 0 10px'
  },
  appVersion: {
    fontWeight: 'bold',
  },
  author: {
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  adChoice: {
    display: 'block',
    width: '75px',
    height: '16px',
    lineHeight: '16px',
    textAlign: 'left',
    background: 'transparent url(https://aboutads.quantcast.com?icon=rg4xE5OTh_1iYBiZFTry2g) right 0 no-repeat',
    color: 'white',
    textDecoration: 'none',
    fontSize: '12px',
    fontFamily: 'arial,helvetica,sans-serif',
    margin: '5px 0 10px 0'
  }
});
class Footer extends React.Component {

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.background}>
        <div className={classes.root}>
          <div className={classes.note}>
            Copyright © 2018 <span className={classes.author}>Jethro Lai</span>. &nbsp;All Rights Reserved.
            &nbsp;&nbsp;&nbsp; Current Version: <span id="appVersion" className={classes.appVersion}/>
            <a className={classes.adChoice} id={"adChoicesLogo"} href={"https://www.quantcast.com/adchoices-pub?pub=rg4xE5OTh_1iYBiZFTry2g"} target={"_blank"}>AdChoices</a>
          </div>
        </div>
      </div>);
      
  }
}

export default withStyles(styles)(Footer);
