import React from "react";
import {withStyles} from "@material-ui/core/styles";
import NavButton from './nav_button';
import About from './about';
import TwitterMentionButton from './twitter_mention_button';
import FacebookLikeButton from './facebook_like_button';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Helmet } from 'react-helmet';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    minWidth: '520px',
    maxWidth: '1060px',
    display: 'inline-block',
    paddingTop: '10px'
  },
  logo: {
    float: 'left',
    width: '250px'
  },
  navigations: {
    float: 'right',
  },
  email: {
    marginRight: '10px',
  },
});

class Header extends React.Component {

  state = {
    aboutOpen: false
  };

  handleAboutOpen = () => {
    this.setState({aboutOpen: true});
  };

  handleAboutClose = () => {
    this.setState({aboutOpen: false});
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>Lotto Intelligence</title>
          <meta name="description" content="Playing lottery game is an awesome entertainment and it’s even more fun when you play it smart. Most of lottery sites provide things like next draw date, winning numbers, current jackpot prize, probabilities, rules and nothing more. There are so many numbers, metrics, stories and inspirations behind each lottery game. Also those sites don't really offer any way to help you pick a number that works best for you. This is why we created  Lotto Intelligence.On Lotto Intelligence, not only do you get to see lottery data in a brand new way but also play the game in a way that makes more sense. We provide various analyses and statistics against entire PowerBall history data visualization number generation with preferred rules and filters. rules and filters statistics number checking with rules We also plan on implementing features like automatic winning notification so that you can log your ticket with us and never have to worry about checking it. We will check the ticket for you and notify you if your ticket is a winner. Additionally, ticket management is also on the roadmap. Currently, we are supporting PowerBall only but soon other major lottery games such as Mega Millions, SuperLotto Plus, Lotto America and so on will be supported in the future. Another important thing is it's absolutely FREE for everyone. Please feel free to give us feedback and suggestions. We are eager to hear from you and improve what we do. We hope you enjoy our site and wish the best of luck to you! – Lotto Intelligence lottointelligence@gmail.com" 
          />
        </Helmet>
        <img className={classes.logo} src='https://s3-us-west-1.amazonaws.com/api.jethrolai.com/lottery/powerball/logo.png' alt='Lotto Intelligence'></img>
        <div className={classes.navigations}>
          <NavButton onClick={this.handleAboutOpen}> About </NavButton>
          <About handleClose={this.handleAboutClose.bind(this)} open={this.state.aboutOpen} ></About>
          <NavButton className={classes.blog} href="https://blog.lottointelligence.com/" target="_blank">
            Blog
          </NavButton>
          <Tooltip title="Email us at lottointelligence@gmail.com and let us know what you think. Feel free to provide your idea with us and we might add it to our site. ">
            <IconButton aria-label="email" className={classes.email} href="mailto:lottointelligence@gmail.com" target="_blank">
              <SvgIcon>
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <TwitterMentionButton screenName={'lottointel'}/>
          <FacebookLikeButton/>
      </div>      
    </div>);
  }
}

export default withStyles(styles)(Header);
