import React, {Component} from 'react';
import './statistics_chart.css';
import Chart from 'react-google-charts';
import _ from 'underscore';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import {withStyles} from '@material-ui/core/styles';
import { filterOldDraws } from './utils/draw_utils';
import { viewTitleStyle }from './constants';

const styles = theme => ({
  root: {
    margin: '10px auto 0 15px',
    padding: '10px',
    width: '530px',
    boxSizing: 'border-box',
  },
  title: viewTitleStyle,
  note: {
    fontStyle: 'italic',
    padding: '10px'
  },
  noteDescription: {
    fontStyle: 'italic'
  },
  noteTitle: {
    fontWeight: 'bold'
  },
  noteContent: {
    '&:hover': {
      cursor: 'pointer',
      color: 'blue',
      textDecoration: 'underline',
    }  
  }
});



class StatisticsSince2015Chart extends Component {

  constructor(props) {
    super(props)    
    this.state = {'draws': filterOldDraws(props.draws, "2015-10-04"), 'changeHistoryDialogOpen': false }
  }
  
  handleClickHistoryDialogOpen = () => {
    this.setState({ 'changeHistoryDialogOpen': true });
  };

  handleClickHistoryDialogClose = () => {
    this.setState({ 'changeHistoryDialogOpen': false });
  };

  calculateOccurrences() {
    const draws = this.state.draws;
    var numberOccurrences = _.range(70).map(function () { return 0 });
    var powerballOccurrences = _.range(27).map(function () { return 0 });
    var powerplayOccurrences = _.range(11).map(function () { return 0 });
    draws.forEach(draw => {
      draw['numbers'].forEach(number => {
        numberOccurrences[number] += 1;
      });
      powerballOccurrences[draw['powerball']] += 1;
      powerplayOccurrences[draw['powerplay']] += 1;
    });
    
    
    var result = {'chartData':[], "chartTitle":undefined};
    result['chartData'][0] = ['Winning Numbers', 'Main Number', 'PowerBall', 'PowerPlay'];
    var i;
    for (i=1; i < numberOccurrences.length; i++) {
      result['chartData'][i] = [`${i}`, numberOccurrences[i], powerballOccurrences[i], powerplayOccurrences[i]];
    }
    //TODO we assume the first item is the latest one and the last one is the oldest; in another word, draws is assumed sorted. 
    result['start'] = draws[draws.length-1]['drawDate'];
    result['end'] = draws[0]['drawDate'];
    result['total'] = draws.length;
    return result;
  }

  render() {
    const { classes } = this.props;
    var chartData = this.calculateOccurrences();
    return (
      <Grow in={true}>
        <Paper className={classes.root}>
          <h1 className={classes.title}>
            {`Main Numbers, PowerBall & PowerPlay Occurrences Over ${chartData['total']} Draws`}<br/>{`Since The Last PowerBall Format Change (${chartData['start']})`}
          </h1>
          <hr/>
          <Chart
            width={'480px'} 
            height={'680px'}
            chartType="BarChart"
            loader = {
              <div>Loading Chart</div>
            }
            data={chartData['chartData']}
            options={{
              chartArea: { 
                width: '63%',
                height: '95%',
              },
              hAxis: {
                title: 'Occurrences',
                minValue: 0,
              },
              vAxis: {
                title: 'Number Value (Applicable To All Of Main Number, PowerBall, PowerPlay)',
              },
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
          />
        <hr/>
        <div className={classes.note}>
            <span className={classes.noteTitle}>Note:&nbsp;</span>
          <span className={classes.noteDescription}>PowerBall has gone through many format changes in the past. This table contains the data after most recent change on October 4, 2015.
              <span className={classes.noteContent} onClick={this.handleClickHistoryDialogOpen}> See PowerBall change history</span>
              <Dialog
                open={this.state.changeHistoryDialogOpen}
                onClose={this.handleClickHistoryDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"PowerBall Change History"}</DialogTitle>
                  <DialogContent>
                    <table className='powerball-change-table'>
                      <tbody>
                        <tr >
                          <th className='powerball-change-table-th'>Starting date</th>
                          <th className='powerball-change-table-th'>Pick 5 of</th>
                          <th className='powerball-change-table-th'>Pick 1 of</th>
                          <th className='powerball-change-table-th'>Jackpot chance</th>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>April 22, 1992</td>
                          <td className='powerball-change-table-td'>45</td>
                          <td className='powerball-change-table-td'>45</td>
                          <td className='powerball-change-table-td'>1:54,979,154</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>November 5, 1997</td>
                          <td className='powerball-change-table-td'>49</td>
                          <td className='powerball-change-table-td'>42</td>
                          <td className='powerball-change-table-td'>1:80,089,127</td>

                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>March 7, 2001</td>
                          <td className='powerball-change-table-td'>49</td>
                          <td className='powerball-change-table-td'>42</td>
                          <td className='powerball-change-table-td'>1:80,089,127</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>October 9, 2002</td>
                          <td className='powerball-change-table-td'>53</td>
                          <td className='powerball-change-table-td'>42</td>
                          <td className='powerball-change-table-td'>1:120,526,769</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>August 28, 2005</td>
                          <td className='powerball-change-table-td'>55</td>
                          <td className='powerball-change-table-td'>42</td>
                          <td className='powerball-change-table-td'>1:146,107,961</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>January 7, 2009</td>
                          <td className='powerball-change-table-td'>59</td>
                          <td className='powerball-change-table-td'>39</td>
                          <td className='powerball-change-table-td'>1:195,249,054</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>January 15, 2012</td>
                          <td className='powerball-change-table-td'>59</td>
                          <td className='powerball-change-table-td'>35</td>
                          <td className='powerball-change-table-td'>1:175,223,510</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>January 19, 2014</td>
                          <td className='powerball-change-table-td'>59</td>
                          <td className='powerball-change-table-td'>35</td>
                          <td className='powerball-change-table-td'>1:175,223,510</td>
                        </tr>
                        <tr>
                          <td className='powerball-change-table-td'>October 7, 2015</td>
                          <td className='powerball-change-table-td'>69</td>
                          <td className='powerball-change-table-td'>26</td>
                          <td className='powerball-change-table-td'>1:292,201,338</td>
                        </tr>
                      </tbody>
                    </table>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClickHistoryDialogClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
              </Dialog>
            </span>
          </div>
        </Paper>
      </Grow>
    );
  }
}



export default withStyles(styles)(StatisticsSince2015Chart);