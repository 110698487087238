import React, {Component} from 'react';
import Grow from '@material-ui/core/Grow';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: '10px',
  },
  title: {
    fontSize: '1.2em',
    margin: '0 0 0 10px',
    fontWeight: 'bold',
  },
  numbers: {
    fontSize: '1.2em',
    color: 'DarkSlateBlue',
  },
  metadataTitle: {
    fontSize: '1em',
    padding: '10px 0 0 10px',
    margin: '3px'
  },
  metadataContent: {
    color: 'DarkSlateBlue',
    fontSize: '1em',
    margin: '5px 0 0 10px'
  },
  metadataFilter: {
    color: 'DarkSlateBlue',
    fontSize: '1em',
  }
});

function decorateWithGrow(element, timeout) {
  return <Grow 
    in={true}
    style={{ transformOrigin: '0 0 0' }}
    timeout={timeout}
    >
    {element}
  </Grow>;
}

class GeneratedTicket extends Component {
  
  render() {
    const { classes } = this.props;
    let filters;    
    if(!this.props['ticket']['filters'] || this.props['ticket']['filters'].length === 0) {
      filters = <div className={classes.metadataContent}> No filters applied! </div>;
    } else {
      filters = <ul style={{margin:"5px"}}>{this.props['ticket']['filters'].map((filter) => <li key={filter} className={classes.metadataFilter}>{filter}</li>)}</ul>;
    }
    
    let generationResult = 
      <div className={classes.root}>
        <div className={classes.title}>Generation Result: <span className={classes.metadataFilter}> {this.props['ticket']['numbers'].join(", ")} ({this.props['ticket']['powerball']})</span></div>            
      </div>;
      
    let generationMetadata = 
      <div className={classes.root}>
        <div className={classes.title}>Generation Metadata:</div>
        <div className={classes.metadataTitle}> Applied Rules:{filters}</div>
        <div className={classes.metadataTitle}> Generation Time:<div className={classes.metadataContent}>{this.props['ticket']['time']}</div></div>
        <div className={classes.metadataTitle}> Generation Duration:<div className={classes.metadataContent}>{this.props['ticket']['elasedTime']} milliseconds</div></div>
      </div>;
    
    if(this.props.animated) {
      generationResult = decorateWithGrow(generationResult, 700);
      generationMetadata = decorateWithGrow(generationMetadata, 1500);
    }
    
    return (
      <div>
        {generationResult}
        {generationMetadata}
      </div>
    );
  }
}

export default withStyles(styles)(GeneratedTicket);

