import _ from 'underscore'

const filterLabelMap = {
  'checkHasAppeared': 'Not a jackpot winner in the history',
  'checkThreeConsecutive': 'No 3 consecutive numbers',
  'checkTwoConsecutive': 'No 2 consecutive numbers',
  'checkAllOddNumbersWithPowerball': 'Only odd numbers including Powerball',
  'checkAllOddNumbers': 'Only odd numbers excluding Powerball',
  'checkAllEvenNumbersWithPowerball': 'Only even numbers including Powerball',
  'checkAllEvenNumbers': 'Only even numbers excluding Powerball',
  'checkPowerBallAndNumberOverlap': 'No overlap between PowerBall and numbers'
}

const allFilters = [
  checkHasAppeared,
  checkThreeConsecutive,
  checkTwoConsecutive,
  checkAllOddNumbersWithPowerball,
  checkAllOddNumbers,
  checkAllEvenNumbersWithPowerball,
  checkAllEvenNumbers,
  checkPowerBallAndNumberOverlap
];

function lookUpLabel(filterFunction) {
  switch (filterFunction) {
    case checkHasAppeared:
      return filterLabelMap['checkHasAppeared'];
    case checkThreeConsecutive:
      return filterLabelMap['checkThreeConsecutive'];
    case checkTwoConsecutive:
      return filterLabelMap['checkTwoConsecutive'];
    case checkAllOddNumbersWithPowerball:
      return filterLabelMap['checkAllOddNumbersWithPowerball'];
    case checkAllOddNumbers:
      return filterLabelMap['checkAllOddNumbers'];
    case checkAllEvenNumbersWithPowerball:
      return filterLabelMap['checkAllEvenNumbersWithPowerball'];
    case checkAllEvenNumbers:
      return filterLabelMap['checkAllEvenNumbers'];
    case checkPowerBallAndNumberOverlap:
      return filterLabelMap['checkPowerBallAndNumberOverlap'];
    default:
      //NOOP
  }
}

function checkHasAppeared(ticket, draws) {
  let localCopyTicket = JSON.parse(JSON.stringify(ticket));
  localCopyTicket['numbers'].sort((a, b) => a - b);

  var result = draws.find(function(element) {
    if (_.isEqual(element['numbers'].map(n => parseInt(n)), localCopyTicket['numbers'].map(n => parseInt(n))) && parseInt(element['powerball']) === parseInt(localCopyTicket['powerball'])) {
      return true;
    }

    return false;
  });
  return result !== undefined;
}

function checkThreeConsecutive(ticket) {
  return checkNConsecutive(ticket, 3);
}

function checkTwoConsecutive(ticket) {
  return checkNConsecutive(ticket, 2);
}

function checkNConsecutive(ticket, numberOfConsecutive) {
  // TODO: validate ticket content
  let localCopyTicket = JSON.parse(JSON.stringify(ticket));
  localCopyTicket['numbers'].sort((a, b) => a - b);
  var index = 0;
  var hitCount = 1;
  while (index < 4) {
    if ((parseInt(localCopyTicket['numbers'][index]) + 1) === parseInt(localCopyTicket['numbers'][index + 1])) {
      hitCount = hitCount + 1;
      if (hitCount === numberOfConsecutive) {
        return true;
      }
    } else {
      hitCount = 1;
    }
    index = index + 1
  }
  return false;
}

function checkAllOddNumbersWithPowerball(ticket) {
  return checkAllOddNumbers(ticket) && ticket['powerball'] % 2 === 1;
}

function checkAllOddNumbers(ticket) {
  return ticket['numbers'].find(number => number % 2 === 0) === undefined;
}

function checkAllEvenNumbersWithPowerball(ticket) {
  return checkAllEvenNumbers(ticket) && ticket['powerball'] % 2 === 0;
}

function checkAllEvenNumbers(ticket) {
  return ticket['numbers'].find(number => number % 2 === 1) === undefined;
}

function checkPowerBallAndNumberOverlap(ticket) {
  return ticket['numbers'].find(number => parseInt(number) === parseInt(ticket['powerball'])) !== undefined;
}

export {
  checkHasAppeared,
  checkThreeConsecutive,
  checkTwoConsecutive,
  checkAllOddNumbersWithPowerball,
  checkAllOddNumbers,
  checkAllEvenNumbersWithPowerball,
  checkAllEvenNumbers,
  checkPowerBallAndNumberOverlap,
  filterLabelMap,
  lookUpLabel,
  allFilters
};