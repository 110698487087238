import { lookUpLabel } from './filter_functions';

function check_filters(draws, filters) {
  /*TODO Validate draws*/
  if (filters === undefined || Array.isArray(filters) === false) {
    return false;
  }

  var results = [];

  filters.forEach(filter => {
    let result = {rule: lookUpLabel(filter), draws: []};
    draws.forEach(draw => {
        if (filter(draw, draws)) {
          result.draws.push(draw);
        };
    });
    results.push(result);
  });
  return results;
}

export {
  check_filters
};