function filterOldDraws(draws, startDateString, isStartDateIncluded) {
  let drawsCopy = draws.slice();
  let startDate = new Date(startDateString);  
  return drawsCopy.filter( d => {
    return isStartDateIncluded ? new Date(d.drawDate) >= startDate : new Date(d.drawDate) > startDate
  });  
}

function filterDrawsWithoutPowerPlay(draws) {
  let drawsCopy = draws.slice();
  return drawsCopy.filter( d => d.powerplay);
}

function isValidDate(dateString) {
  if (!dateString) {
    return false;
  }
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  if(Number.isNaN(d.getTime())) return false; // Invalid date
  return d.toISOString().slice(0,10) === dateString;
}

function isValidDrawDate(draws, dateString) {
  return isValidDate(dateString) && draws.some(d => dateString===d.drawDate);
}

export {
  filterOldDraws,
  filterDrawsWithoutPowerPlay,
  isValidDate,
  isValidDrawDate
};