import {withStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const GenerateButton = withStyles({
  root: {
    fontWeight: '500',
    fontFamily: 'Thasadith, sans-serif',
    color: '#486bd6',
    background: 'linear-gradient(to right, #fad0fb, #b6fdf2);',
    minHeight: "25px",
  },
  label: {
    textTransform: 'capitalize'
  }
})(Button);

export default GenerateButton;
