import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '1070px',
    margin: '0 auto 10px auto',
    padding: '10px',
    boxSizing: 'border-box',
    backgroundColor: '#fff2f7'
  },
  lotteryImage: {
    width: '100px',
    display: 'inline',
    verticalAlign:'middle'
  }
});

class GeneralNote extends Component {

  render() {
    const {classes} = this.props;
    return (<Paper className={classes.root}>
      <ul >
        <li>
          All data shown and functions in the current page are designed for :
          <a href="https://www.powerball.com" target='_blank' rel="noopener noreferrer">
            <img className={classes.lotteryImage} src="/Powerballlogo.png" alt="PowerBall"></img>
          </a>
        </li>
        <li>
          The latest drawn numbers will be added to the analysis with a delay. Normally, it will take a few minutes to few hours for the latest draw to be included.
        </li>
      </ul>
    </Paper>);
  }
}

export default withStyles(styles)(GeneralNote);